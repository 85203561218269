<template>
    <Modal>
        <h1>{{ headline }}</h1>
        <Form @submit="submit" @attempt="attempt">
            <fieldset>
                <Input type="text" placeholder="Emne*" :required="true" v-model="fields.title"/>
                <Input type="text" placeholder="Beskrivelse af ændring*" :required="true" v-model="fields.description"/>
                <Price placeholder="Aftalt pris*" :required="true" v-model="fields.price" class="half"/>
                <Input type="number" placeholder="Ekstra dage*" v-model="fields.days" :required="true" class="half"
                       tooltip="Hvilken tidsmæssig konsekvens i dage, vil tillægskontrakten have?"/>
                <Upload label="Vedhæft tilbud" v-model="fields.file"/>
            </fieldset>
            <ActionButton :icon="['check', 'times']" :float="true" :focus-icon="focusIcon"/>
            <OverlayButtons :show="showOverlayButton">
                <ActionButton icon="paperplane" label="Send tillæg/fradrag" :small="true" :secondary="true" @click="send"/>
                <ActionButton icon="save" label="Gem som kladde" :small="true" :secondary="true" @click="save"/>
            </OverlayButtons>
        </Form>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Form from '@/components/Form.vue';
import Upload from '@/components/inputs/Upload.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import Input from '@/components/inputs/Input.vue';
import OverlayButtons from '@/components/buttons/OverlayButtons.vue';
import { uploadFile } from '@/utils/firebase';
import { additionsCollection } from '@/utils/collections';
import { useRoute } from 'vue-router';
import Price from '@/components/inputs/Price.vue';
import methods from '@/utils/methods';
import useState from '@/store/index';

export default {
    name: 'Addition',
    components: {
        Modal,
        Form,
        Upload,
        ActionButton,
        Input,
        Price,
        OverlayButtons,
    },
    mixins: [methods],
    setup() {
        const { project, setModalData } = useState();
        const route = useRoute();

        const { id, contract } = route.params;
        const edit = !!id;
        const headline = edit ? 'Rediger tillæg/fradrag til kontrakt' : 'Opret tillæg/fradrag til kontrakt';

        return { edit, headline, contract, id, project, setModalData };
    },
    data() {
        return {
            fields: {
                title: null,
                description: null,
                price: null,
                days: null,
                file: null,
            },
            validated: false,
            showOverlayButton: false,
        };
    },
    computed: {
        focusIcon() {
            return Number(this.showOverlayButton);
        },
    },
    async mounted() {
        if (this.edit) await this.setupData();
    },
    methods: {
        attempt() {
            this.validated = false;
            this.showOverlayButton = false;
        },
        submit() {
            this.validated = true;
            this.showOverlayButton = !this.showOverlayButton && this.validated;
        },
        async send() {
            const addition = this.edit ? await this.update() : await this.create();
            await this.setModalData(addition);
            await this.$router.push({
                name: 'view-addition',
                params: { id: addition.id },
                query: { action: 'send' },
            });
        },
        async save() {
            if (this.edit) await this.update();
            else await this.create();
            await this.$router.push({ name: 'view-contract', params: { id: this.contract } });
        },
        async create() {
            try {
                this.fields.created = new Date();
                const data = this.removeNullValues(this.fields);

                if (data.file) data.file = uploadFile(data.file, data.file.name, this.project.id);

                const addition = await additionsCollection(this.contract).add(data);
                return { id: addition.id, ...data };
            } catch (err) {
                console.log(err);
            }

            return null;
        },
        async update() {
            try {
                const data = this.removeNullValues(this.fields);

                data.file = data.file ? this.uploadOrUseLocalFile(data.file) : null;

                await additionsCollection(this.contract).doc(this.id).update(data);
                return data;
            } catch (err) {
                console.log(err);
            }

            return null;
        },
        async setupData() {
            try {
                const addition = await additionsCollection(this.contract).doc(this.id).get();
                const data = addition.data();
                this.fields = { ...this.fields, ...data };
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style scoped lang="less">

</style>
